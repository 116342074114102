import { useQuery } from '@tanstack/react-query';
import { cleanLocation, getProductsByIds } from '@util/firestore/products';
import { client } from '@util/getTypesense';
import { useAuth } from 'context/AuthContext';
import { UserProfile } from 'models/user';

const useRecentlyViewed = () => {
  const { userDoc } = useAuth();

  const { data: recentlyViewedProducts } = useQuery({
    queryKey: ['recentlyViewedProducts', userDoc?.uid ?? ''],

    queryFn: async () => {
      const user_profile_index = 'user_profiles_alias';

      const user_profile_res = await client
        .collections(user_profile_index)
        .documents()
        .search({
          q: '*',
          query_by: 'uid',
          filter_by: `uid:=${userDoc?.uid}`,
        });
      const user_profile = user_profile_res.hits?.[0].document as UserProfile;

      if (!user_profile) {
        return [];
      }
      const views =
        user_profile.interactions?.views
          ?.sort((v1, v2) => (v1.date ?? 0) - (v2.date ?? 0))
          .map((p) => p.pid) ?? [];
      if (views.length === 0) {
        return [];
      }
      const products = await getProductsByIds(views.slice(0, 30));
      return products
        .sort((a, b) => {
          const viewed_a = user_profile.interactions?.views?.find(
            (v) => v.pid === a.id
          );
          const viewed_b = user_profile.interactions?.views?.find(
            (v) => v.pid === b.id
          );
          if (!viewed_a || !viewed_b) {
            return 0;
          }
          return (viewed_b.date ?? 0) - (viewed_a.date ?? 0);
        })
        .map((p) => cleanLocation(p));
    },
    enabled: !!userDoc?.uid,
    staleTime: 1000 * 60 * 5, // 5 minutes
  });

  return {
    recentlyViewedProducts,
  };
};

export default useRecentlyViewed;
