import { ArrowUpRightIcon } from '@c/icons';
import SafeImage from '@ui/SafeImage';
import { CuratedListDocument } from '@models/curated_list';
import Link from 'next/link';
import { isMobile } from '@util/index';

const CuratedLists = ({ lists }: { lists: CuratedListDocument[] }) => {
  return (
    <div className="flex max-w-default flex-col gap-[3.2rem] lg:gap-[6.4rem]">
      <h3 className="pl-8 text-left text-[2.4rem] font-semibold sm:pl-0 sm:text-center lg:text-[3.2rem]">
        Curated by MX Locker
      </h3>

      <div
        className={`mx-auto flex w-full gap-[1.6rem] overflow-x-scroll pl-8 ${
          isMobile() && 'scrollbar-none'
        } sm:pl-0`}
      >
        {lists?.map((curatedList) => (
          <CuratedListCard
            key={curatedList.id}
            text={curatedList.title}
            src={curatedList.card_src}
            href={`/shop/curated/${curatedList.slug}`}
          />
        ))}
      </div>
    </div>
  );
};

const CuratedListCard = ({
  text,
  src,
  href,
}: {
  text: string;
  src: string;
  href: string;
}) => (
  <Link
    href={href}
    className="group relative flex min-h-[44rem] min-w-[28.8rem] overflow-clip rounded-[2rem]"
  >
    <h4 className="absolute bottom-[2.4rem] left-[2.4rem] z-20 flex h-[6.2rem] w-[28.8rem] items-end pr-[2.4rem] text-[2.4rem] font-semibold text-white">
      {text}
    </h4>

    <div className="absolute right-[2.4rem] top-[2.4rem] z-20 text-white">
      <ArrowUpRightIcon width={24} height={24} />
    </div>

    <div className="absolute inset-0 z-10 bg-curated-list-banner " />

    <SafeImage
      fill
      src={src}
      width={480}
      height={620}
      alt={text}
      classes="lg:transition-transform lg:duration-200 lg:delay-0 lg:ease-in-out lg:group-hover:scale-105 object-cover"
    />
  </Link>
);

export default CuratedLists;
